/* index.css */
@font-face {
  font-family: 'Fortnite';
  src: url('./Fortnite.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./fortnite.jpg');
  background-size: 100% 100%; /* Or remove this line entirely */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Fortnite', sans-serif;
  overflow: hidden;
}



.bionicle {
  /* Add styles to position the GIF */
  position: absolute; /* Adjust the left position as needed */
  right: 470px;
  bottom: 20px;  
  height: 80%; /* This will maintain the aspect ratio */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
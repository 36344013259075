/* Fortnite Button Styles */
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.button-container {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  position: fixed;
  bottom: 520px;
  right: 30px;
  
}

.shared-button {
  height: 75px;
  width: 325px;
  background: linear-gradient(#fefb72, lighten(#fefb72, 35%));
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Luckiest Guy', cursive;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.shared-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px 0px rgba(17, 17, 17, 0.8);
}

.button-inner {
  height: 75%;
  width: 90%;
  background: linear-gradient(darken(#fefb72, 35%), #fefb72);
  transform: skew(-5deg);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.button-icon {
  width: 40px;
  height: 40px;
  margin-left: 15px;
}

.button-text {
  position: relative;
  bottom: 5px;
  font-size: 3rem;
  color: #2c3076;
  margin: 15px 0 0 0;
  font-family: 'Luckiest Guy', cursive;
}

.shared-button a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
}